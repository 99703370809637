import axios from 'axios';
import axiosCancel from 'axios-cancel';

axios.defaults.withCredentials = true;
axiosCancel(axios, {
  debug: false,
});

const errorData = {
  isError: true,
  statusCode: -10001,
  message: '接口异常',
  data: {
    SUCCESS: false,
    MSG: '请求异常',
  },
};

const get = async (url, params = {}, headers) => {
  axios.cancel(url);
  try {
    Object.keys(params).map(item => {
      return params[item] = decodeURIComponent(params[item]);
    });

    let options = {
      method: 'get',
      url,
      noCache: true,
      params: params,
      timeout: 30000,
      headers: {
      },
    };

    options.requestId = url;
    const response = await axios(options);
    return response;
  } catch (err) {
    return errorData;
  }
};

const post = async (url, params) => {
  axios.cancel(url);

  params = params || {};
  try {
    const response = await axios({
      url,
      method: 'post',
      data: params,
      requestId: url,
      timeout: 30000,
      headers: {

      },
    });
    return response;
  } catch (err) {
    return errorData;
  }
};
const put = async (url, params) => {
  axios.cancel(url);
  params = params || {};
  try {
    const response = await axios({
      url,
      method: 'put',
      data: params,
      requestId: url,
      timeout: 30000,
      headers: {
      },
    });
    return response;
  } catch (err) {
    return errorData;
  }
};

const postNotJson = async (url, params) => {
  axios.cancel(url);

  params = params || {};
  try {
    const response = await axios({
      url,
      method: 'post',
      data: params,
      requestId: url,
      timeout: 30000,
      transformRequest: [
        function(data) {
          let ret = '';
          for (let it in data) {
            if (data[it] == null) {
              data[it] = '';
            }
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
          }
          ret = ret.substring(0, ret.length - 1);
          return ret;
        },
      ],
      headers: {
    
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
    return response;
  } catch (err) {
    return errorData;
  }
};

const download = async (url, fileName) => {
  axios.cancel(url);
  try {
    const response = await axios({
      url,
      method: 'get',
      requestId: url,
      timeout: 30000,
      headers: {

      },
    });

    const urltemp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = urltemp;
    if (fileName != null) {
      link.setAttribute('download', fileName);
    } else {
      var contentDisposition = response.headers['content-disposition'];
      var dispositions = contentDisposition.split('=');
      link.setAttribute('download', decodeURI(dispositions[1]));
    }

    document.body.appendChild(link);
    link.click();
  } catch (err) {
    return errorData;
  }
};

export {
  get,
  post,
  put,
  download,
  postNotJson,
};