// eslint-disable-next-line
import React, { Component } from 'react'
import logo from './logo.svg';
import './App.less';
import { BASE_URL } from "@/api/url"
import * as XLSX from 'xlsx';
import { Upload, message, Row, Col } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

class App extends Component{

  constructor () {
    super()
    this.state ={
       excelData: [],
       divisionData: []
    }
  }

  onDivisionExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以二进制流方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据
        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            // break; // 如果只取第一张表，就取消注释这行
          }
        }

        this.setState({
          divisionData: data
        })
      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log('文件类型不正确');
        return;
      }
    };
    // 以二进制方式打开文件
    if (files.length > 0) {

      fileReader.readAsBinaryString(files[0]);
    }
  }

  onImportExcel = file => {
    console.log(file)
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以二进制流方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据
        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            // break; // 如果只取第一张表，就取消注释这行
          }
        }
        console.log(data)
        this.setState({
          excelData: data
        })
      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log('文件类型不正确');
        return;
      }
    };
    console.log(files)
    // 以二进制方式打开文件
    if (files.length > 0) {

      fileReader.readAsBinaryString(files[0]);
    }
  }

  render () {
    const elements=[];
    this.state.excelData.forEach((item)=>{
      let report_frequency = "A"
      switch(item.data_interval){
        case "年度":
          report_frequency = "A"
          break
        case "年":
          report_frequency = "A"
          break
        case "季度":
          report_frequency = "C"
          break
        case "月":
          report_frequency = "D"
          break
        case "天":
          report_frequency = "F"
          break
        case "日":
          report_frequency = "F"
          break
        case "实时":
          report_frequency = "F"
          break
        case "不定期":
          report_frequency = "F"
          break
        default:
          break
      }
      elements.push(
        <div key={item.__rowNum__}>
          ('{item.name}','{item.id}','{item.code}','ORG_ID','GATHERER_ID','AUDITOR_ID','{report_frequency}','B',
            'A',0,'2021-1-5 15:43:07',1,'2021-1-5 15:43:21','2021-1-6 00:00:00',0,'{item.topic_id}',
            '{item.theme}','{item.THEME_DESCRIBE}','{item.domain_id}','{item.domain}','{item.DEPT_LEADER}',
            '{item.DEPT_LEADER_PHONE}','{item.AREA_ID}','{item.AREA_TYPE}','{item.AREA_NAME}'),
        </div>
      )
    });

    const delements = [];
    this.state.divisionData.forEach((item) => {
      delements.push(
         <div key={item.__rowNum__}>
          ('{item.id}','{item.ext_id}','{item.ext_name}'),
         </div>
      )
    })

    const props = {
      name: 'file',
      multiple: true,
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
    return (
        <div className="App ">
          success
        </div>
    );
  }
}

export default App;
