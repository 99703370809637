var _ = require('lodash');

const getQueryVariable = (variable, query)=>
{       
    query = _.trimStart(query,"?");

    var vars = query.split("&");
    var varsLen = vars.length;
    for (var i=0;i<varsLen; i++) {
        var pair = vars[i].split("=");
        if(pair[0] === variable){return pair[1];}
    }
    return(false);
};

export {
    getQueryVariable
}