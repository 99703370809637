import { get, post } from '@/api'
import { API_URL } from "./url"

const queryDisclaimer = (openid) =>{

    return get(`${API_URL}disclaimer`, {openid }).then(res => res.data)
};

const queryTheme = (storeId) =>{

    return get(`${API_URL}theme`, {storeId }).then(res => res.data)
};

const querySubmit = (params) =>{

    return post(`${API_URL}disclaimer/do`, params).then(res => res.data)
};

export {
    queryDisclaimer,
    queryTheme,
    querySubmit
}