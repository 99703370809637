import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { Tabs, Flex, List, Picker, Badge, Button, InputItem, Radio, Toast } from 'antd-mobile';
import { getQueryVariable } from "@/utils/commonUtils";
import { message } from "antd"

import "./index.less"

var _ = require("lodash")

let storesList = [[],];
let themesList = [[],];
@inject("DisclaimerStore")
@observer
class Index extends Component{

    constructor () {
        super()
        this.state = {
            storeVisible: false,
            sLalbel: ['请选择'],
            sValue: null,

            themeVisible: false,
            tLalbel: ['请选择'],
            tValue: null,

            isAgree: true,
            agreenText: "确定？",

            hasPhoneError: false,
            phone: null,

            realname: null,
            openid: null,
        }

        this.storeAction = this.storeAction.bind(this);
        this.themeAction = this.themeAction.bind(this)
        this.reset       = this.reset.bind(this)
    }

    componentDidMount(){

        const openid = getQueryVariable("openid",this.props.location.search)
        if(!openid){

            message.error(`您的用户认证为空`)
        }else{

            this.setState({
                openid
            })
            const { queryStore } = this.props.DisclaimerStore
            queryStore(openid)
        }
    }

    componentWillUnmount(){

        const { resetSimul } = this.props.DisclaimerStore;
        resetSimul()
    }

    reset = ()=>{
        
        this.setState({

            storeVisible: false,
            sLalbel: ['请选择'],
            sValue: null,

            themeVisible: false,
            tLalbel: ['请选择'],
            tValue: null,

            isAgree: true,
            agreenText: "确定？",

            hasPhoneError: false,
            phone: null,

            realname: null,
        })
    }

    storeAction = (v) =>{
        
        const { getThemeByStore } = this.props.DisclaimerStore; 

        const store = this.getStoreName(v)

        getThemeByStore(store['id'])

        this.setState({ 
            sLalbel: [store['label'],],
            sValue: v, 
            storeVisible: false 
        })
    };

    getStoreName = (code)=>{

        for(let j=0; j< storesList[0].length; j++){

            if(storesList[0][j]['value'] === code.toString()){
                return {
                    id:     storesList[0][j]['id'],
                    label:  storesList[0][j]['label']
                };
            }
        }
    };

    themeAction = (v) =>{

        const themeName = this.getThemeName(v);
        // console.log(themeName)
        this.setState({ 
            tLalbel: [themeName,],
            tValue: v, 
            themeVisible: false  
        })
    };

    getThemeName = (val)=>{
        // console.log(themesList)
        for(let j=0; j< themesList[0].length; j++){
            // console.log(val)
            if(themesList[0][j]['value'] === parseInt(val.toString())){
                return themesList[0][j]['label'];
            }
        }
    };

    onPhoneErrorClick = ()=>{
        if (this.state.hasPhoneError) {
            Toast.info('手机号码格式不正确');
        }
    };

    onPhoneChange = (value)=>{

        if (value.replace(/\s/g, '').length < 11) {
            this.setState({
                hasPhoneError: true,
            });
        } else {
            this.setState({
                hasPhoneError: false,
            });
        }
        this.setState({
            phone: value,
        });
    };

    onNameChange = (value)=>{

        this.setState({
            realname: value
        })
    };

    formSubmit = async()=>{
        
        if(!this.state.sValue){
            
            Toast.info("请选择门店");
            return;
        }
        if(!this.state.tValue){

            Toast.info("请选择游戏主题");
            return;
        }
        if(!this.state.realname){

            Toast.info("请填写真实姓名");
            return;
        }
        if(!this.state.phone){

            Toast.info("请填写手机号码");
            return;
        }
        if(this.state.hasPhoneError){
            return;
        }
        if(!this.state.openid){
            
            Toast.info("用户认证ID不能为空");
            return;
        }

        const stores = this.getStoreName(this.state.sValue.toString())

        const { queryFormSubmit } = this.props.DisclaimerStore
        const params = {
            openid: this.state.openid,
			shop: stores['id'],
			theme: this.state.tValue.toString(),
			name: _.trim(this.state.realname),
			phone: this.state.phone.replace(/\s*/g, ''),
        }

        const formResult = await queryFormSubmit(params)
        if(!formResult.success){

            Toast.info(formResult.message);
        }else{

            this.reset()
            Toast.info(formResult.message);
        }
    };

    render(){
        
        const { stores, themeList } = this.props.DisclaimerStore
        for(let i=0; i<stores.length; i++){
            if(i===0){
                storesList[0] = []
            }
            storesList[0].push({
                id:   stores[i]['id'],
                label: stores[i]['name'],
                value: stores[i]['code'],
            })
        }
        for(let j=0; j<themeList.length; j++){
            if(j===0){
                themesList[0] = []
            }
            themesList[0].push({
                label: themeList[j]['theme'],
                value: themeList[j]['id'],
            })
        }
   
        return (
            <div className="discla_container text-center">
                <div className="discla_header">
                    <h2>屋有岛深度体验密室</h2>
                    <div className="summary">
                        <p>玩家登记及免责声明</p>
                    </div>
                </div>
                <div className="discla_content text-left">
                    <p>我们致力于为您提供最优的游戏体验感</p>
                    <p>但更希望您 <b>安全</b></p>
                    <p>因此请您务必仔细阅读以下内容</p>
                    <p><strong>1.</strong>本游戏含有恐怖惊悚元素，您确认并承诺自身不具有心脏病、高血压、脑血栓等心脑血管疾病、精神性疾病（含家族遗传史）、孕妇及未成年人、未满十六周岁、已满五十周岁，年老体弱、饮酒、醉酒、酗酒或身体、精神状况欠佳等禁止入场的情况。若出现隐瞒上述情况参与游戏而引起的任何伤害，均由您自行承担。</p>
                    <p><strong>2.</strong>为保证您更好的游戏体验，主题内有工作人员扮演的角色与您互动，但仅限于部分轻微的肢体接触及语言交流。您已知悉该等游戏设计并做好了相应心理准备。</p>
                    <p><strong>3.</strong>若在密室体验过程中，您感到任何身体不适或出现任何紧急情况，请及时用对讲与门店工作人员联系，工作人员会立刻将您带离主题场景。您在入场前已确认对讲机能够正常使用，并知悉该等紧急情况下的应急安排。</p>
                    <p><strong>4.</strong>游戏中您可以随时选择退出，工作人员会及时将您带出密室。一旦退出密室，将不允许再次入场。</p>
                    <p><strong>5.</strong>密室中灯光昏暗，特定地点空间狭小，为避免对您造成人身损害或店内道具、设施损坏，在此特别提示您密室游戏中不需要进行任何激烈活动，所有机关也不需要使用暴力开启。</p>
                    <p><strong>6.</strong>您同意在出现违反下列门店禁止性规定的情况时，游戏将立即终止，由此造成的任何损失或损害将由您赔偿、承担；如涉嫌侵犯知识产权、商业秘密、公共安全等情形的，屋有岛也将采取包括报警、起诉在内的法律措施：
                        不听从工作人员安排，破坏密室内道具、设施，推搡、殴打、谩骂工作人员或密室游戏中角色扮演者，携带饮料、食品，携带手机或其他拍照录像设备，尖锐物品、打火机以及其他容易造成人员伤害的物品。</p>
                    <p><strong>7.</strong>您承诺并确认入场前已经将钱包、项链、手镯、手表等贵重物品、财物存放于门店寄存柜中，并未携带入场。</p>
                    <p><strong>8.</strong>凡因您违规或自动放弃而终止游戏的，购票款项不予退回，您同意上述安排。</p>
                </div>
                <div className="discla_footer text-left">
                    <div className="box justify-between mt5">
                        <div className="stitle flex1"><span className="red pr5">*</span>您所在的门店</div>
                        <div className="select_control box flex1" onClick={()=> { this.setState({ storeVisible: !this.state.storeVisible})}}>
                            <div className="value">{this.state.sLalbel}</div>
                            <div className="corner"></div>
                        </div>
                    </div>
                    <div className="box justify-between mt5">
                        <div className="stitle"><span className="red pr5">*</span>游戏主题</div>
                        <div className="select_control box" onClick={()=> { this.setState({ themeVisible: !this.state.storeVisible})}}>
                            <div className="value">{this.state.tLalbel}</div>
                            <div className="corner"></div>
                        </div>
                    </div>
                    <List className="datax">
                        <InputItem
                            type="text"
                            placeholder="请输入姓名"
                            onChange={this.onNameChange}
                            value={this.state.realname}
                        ><div className="stitle"><span className="red pr5">*</span>您的姓名</div></InputItem>
                        <div className="remark small">为保证您的个人权益，请填写您的真实姓名</div>
                        <InputItem
                            type="phone"
                            placeholder="请输入手机号码"
                            error={this.state.hasPhoneError}
                            onErrorClick={this.onPhoneErrorClick}
                            onChange={this.onPhoneChange}
                            value={this.state.phone}
                        ><div className="stitle"><span className="red pr5">*</span>手机号码</div></InputItem>
                    </List>

                    <div className="small checkOk mt15">
                        签署本游戏入场注意事项，代表您已经充分明确、了解并同意入场承诺事项所列内容，同时也知悉并将遵守线上《主题注意事项》所列要求。
                        您承诺遵守上述规，并认可屋有岛已经妥善履行注意义务。您同意任何因违反上述规则而造成屋有岛或您的损失、伤害均由您自行承担。
                        <label className="checkbox-inline">
                            <Radio checked={!this.state.isAgree} className="ok-radio" onChange={e => this.setState({ isAgree: false, agreenText: "已确定"})}>{this.state.agreenText}</Radio>
                        </label>
                    </div>
                    <Button 
                        type="primary"
                        size="small"
                        className="full mt10"
                        disabled={this.state.isAgree}
                        onClick={this.formSubmit}
                    >
                        提交
                    </Button>

                </div>
                <Picker
                    visible={this.state.storeVisible}
                    data={storesList}
                    cascade={false}
                    value={this.state.sLalbel}
                    onOk={this.storeAction}
                    onDismiss={() => this.setState({ storeVisible: false })}
                />

                <Picker
                    visible={this.state.themeVisible}
                    data={themesList}
                    cascade={false}
                    value={this.state.tLalbel}
                    onOk={this.themeAction}
                    onDismiss={() => this.setState({ themeVisible: false })}
                />
            </div>
        );
    }
}   

export default Index