import { get, post } from '@/api'
import { API_URL } from "./url"

const queryCreditInfo = (openid) =>{

    return post(`${API_URL}credit`, {openid }).then(res => res.data)
};

const queryRanking = (params={}) =>{

    return get(`${API_URL}ranking`, params).then(res => res.data)
};

export {
    queryCreditInfo,
    queryRanking
}