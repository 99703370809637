import {createBrowserHistory} from 'history';
import React from 'react';
import {Route,Router} from 'react-router';
import App from '@/pages/app/App';
import Credit from '@/pages/credit/index'
import Disclaimer from '@/pages/disclaimer/index'

const history = createBrowserHistory()

const RouterFunc = () => (
    <Router history={history}>
      <>
        <Route exact path="/" component={App}/>
        <Route path="/credit" component={Credit}/>
        <Route path="/disclaimer" component={Disclaimer}/>
      </>
    </Router>
);

export default RouterFunc;