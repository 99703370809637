import { observable, action, configure } from 'mobx'

configure({ enforceActions: true})

class Menu{

    @observable menuCenter = ""

    @action setMenuCenter = menuCenter=>{
        this.menuCenter = menuCenter
    }
}

const menu = new Menu()

export default menu