import React, { Component } from 'react'
import "./index.less"
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { Tabs, Flex, DatePicker, List, Picker, Badge } from 'antd-mobile';
import { getQueryVariable } from "@/utils/commonUtils"

import top1Png from "@/images/top1.png"
import top2Png from "@/images/top2.png"
import top3Png from "@/images/top3.png"
import usrCapPng from "@/images/usrcap.png"

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);
const _ = require('lodash');

const district = [
    {value: 0, label: "全部"},
    {value: "changsha", label: "长沙"},
    {value: "xian", label: "西安"},
    {value: "wuhan", label: "武汉"},
]

@inject("CreditStore")
@observer
class Index extends Component{

    constructor () {
      super()
      this.state ={
        date: now,
        visible: false,
        dpValue: null,
        cityValue: null,
        cityVisible: false,
        currentIndex: 0,
      }

      this.changeCity   =   this.changeCity.bind(this)
      this.changeMonth  =   this.changeMonth.bind(this)
    }
    
    componentDidMount(){

        const openid = getQueryVariable("openid",this.props.location.search)
        const { getCreditInfo} = this.props.CreditStore;
        getCreditInfo(openid)
        this.queryRanking()
    }

    componentWillUnmount(){

        const { resetSimul } = this.props.CreditStore;
        resetSimul()
    }

    queryRanking(params={}){
        
        if("city" in params){
            if(_.isEmpty(params["city"])){
                delete params["city"];
            }
            //eslint-disable-next-line
            if(params["city"] == 0){
                delete params["city"];
            }
        }
        if("date" in params){
            if(_.isEmpty(params["date"])){
                delete params["date"];
            }
        }
        const { getRanking } = this.props.CreditStore;
        getRanking(params)
    }

    renderTabBar(props) {
        // console.log(props)
        return (<div className="mtab">
          {<Tabs.DefaultTabBar {...props} />}
        </div>);
    }

    formatDate(date) {
        /* eslint no-confusing-arrow: 0 */
        if(!date) return null;
        const pad = n => n < 10 ? `0${n}` : n;
        const dateStr = `${date.getFullYear()}-${pad(date.getMonth() + 1)}`;
        return `${dateStr}`;
    }

    formatCity(key){
        
        if(key !== null){
            var keyValue = key[0];
            for(var i in district){
                if(district[i].value === keyValue){
                    return district[i].label;
                }
            }
        }
        return "全部";
    }

    changeCity(val){

        this.queryRanking({
            city: val,
            date: this.formatDate(this.state.dpValue)
        })
        this.setState({
            cityValue: val,
            cityVisible: false 
        });
    }

    changeMonth(date){

        this.queryRanking({
            city: this.state.cityValue,
            date: this.formatDate(date)
        })
        this.setState({ dpValue: date, visible: false })
    }

    render () {
        
        const { credit, user } = this.props.CreditStore; 
        
        const creditRet = toJS(credit)
        const userList  = toJS(user)

        const tabs = [
            { title: <div >榜单</div> },
            { title: <div >奖励说明</div> },
          ];

        return (
            <div className="container">
                <div className="credit_header text-center">
                    <div className="box justify-center select_container" >

                        <div className="credit_select center box" onClick={() => this.setState({ cityVisible: !this.state.cityVisible})}>
                            <div className="big gray1" >{this.formatCity(this.state.cityValue)}</div>
                            <div className="corner"></div>
                        </div>
                        <div className="credit_select center box" onClick={() => this.setState({ visible: !this.state.visible})}> 
                            <div className="big gray1 datevh">{this.formatDate(this.state.dpValue)}</div>
                            <div className="corner"></div>
                        </div>
                    </div>
                    <Picker 
                        visible={this.state.cityVisible}
                        data={district} 
                        cols={1} 
                        value={this.state.cityValue}
                        onOk={this.changeCity}
                        onDismiss={() => this.setState({ cityVisible: false })}
                    />
                    <DatePicker
                        mode="month"
                        visible={this.state.visible}
                        value={this.state.dpValue}
                        onOk={this.changeMonth}
                        onDismiss={() => this.setState({ visible: false })}
                    />
                </div>
                <div className="credit_content">
                    <Tabs tabs={tabs}
                        initialPage={0}
                        renderTabBar={this.renderTabBar}
                        onChange={(tab, index) => { console.log('onChange', index, tab); }}
                        onTabClick={(tab, index) => { console.log('onTabClick', index, tab); }}
                        tabBarBackgroundColor="none"
                        >
                        <div className="credit_tab_content">
                            <div className="thirdTabs box">
                                <div 
                                    className={this.state.currentIndex === 0 ? "third selected" : "third"}
                                    onClick={()=>{ this.setState({currentIndex: 0 })}}
                                    >
                                        我的榜单
                                    </div>
                                <div 
                                    className={this.state.currentIndex === 1 ? "third selected" : "third"}
                                    onClick={()=>{ this.setState({currentIndex: 1 })}}
                                    >
                                        榜单排行
                                    </div>
                            </div>
                            {
                            this.state.currentIndex === 0 ?
                            (
                                <div className="ownerbas box">
                                    <div className="owner-left">
                                        <div className="text-center bt">
                                            <img className="crown" src={usrCapPng} alt="" />
                                            <div className="avater center">
                                                <img className="head" src={userList.pic} alt=""  />
                                            </div>
                                            <div className="bt-title">
                                                <h3 className="white">{userList.nickname}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="owner-right">
                                        <div className="fh">
                                            <span>我的榜单</span>
                                            <Badge text={`排名${creditRet.rank ? creditRet.rank: 0}`} style={{ marginLeft: 12,backgroundColor: "#DE4B30"}} />
                                        </div>
                                        <div className="box">
                                            <div className="flex1">
                                                <div className="bt-title">
                                                    <h3 className="dark-gold number">{creditRet.cityName ? creditRet.cityName : "无"}</h3>
                                                    <div className="litter">当前城市</div>
                                                </div>
                                            </div>
                                            <div className="flex1">
                                                <div className="bt-title">
                                                    <h3 className="dark-gold number">{creditRet.jifen ? creditRet.jifen : 0}</h3>
                                                    <div className="litter">累计积分（分）</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            (<div className="thirdbas" >
                                
                                <Flex align={"stretch"}>
                                    { 
                                        this.props.CreditStore.creditFirstList.map((item, index) => {
                                            
                                            return (
                                                <Flex.Item key={index}>
                                                    <div className={index === 1 ? "text-center mst" : "text-center bt"}>
                                                        <img className="crown" src={((index)=> {
                                                      
                                                            switch(index){
                                                                case 1:
                                                                    return top1Png;
                                                                case 2:
                                                                    return top3Png;
                                                                default:
                                                                    return top2Png;
                                                            }
                                                        })(index)} alt="" />
                                                        <div className="avater center">
                                                            <img className="head" src={item.pic} alt=""  />
                                                        </div>
                                                        <div className="bt-title">
                                                            <h3 className="white">{item.nickname}</h3>
                                                            <h3 className="dark-gold number">{item.jifen}</h3>
                                                            <div className="litter gray">累计积分（个）</div>
                                                        </div>
                                                    </div>
                                                </Flex.Item>
                                            );
                                        })
                                    }
                                </Flex>
                            </div>)
                            }
                            <div className="rank_list">
                                {
                                    this.props.CreditStore.creditFourList.map((item, index) =>{
                                        return (
                                            <div className="rank_li box items-center" key={index}>
                                                <div className="nub">{index+4}</div>
                                                <div className="rank_ico">
                                                    <div className="avater">
                                                        <img src={item.pic} alt="" />
                                                    </div>
                                                </div>
                                                <div className="rank_name">
                                                    {item.nickname}
                                                </div>
                                                <div className="rceil">
                                                    <div className="value">
                                                        <span className="big">{item.session}</span>
                                                        <span>场</span>
                                                    </div> 
                                                    <div className="name">
                                                        累计场次
                                                    </div>
                                                </div>
                                                <div className="rceil">
                                                    <div className="value">
                                                        <span className="big rose">{item.jifen}</span>
                                                        <span className="rose">分</span>
                                                    </div>
                                                    <div className="name">
                                                        累计积分
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                <div className="credit_content_footer text-center mt25">
                                    <div className="big35 a5">
                                        一切解释权归屋有岛官方所有
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="credit_tab_content">
                            <div className="remark">
                                <div className="title">
                                    <div className="big rose">奖励说明</div>
                                </div>
                                <div className="content">
                                    <p>本次活动绝对尊重竞赛精神，本着公平、公正、公开的原则，采用系统的赛制、线上线下的互动、公开透明的投票方式，并辅以专家评委的评论，力求做到人人可参与、晋级看实力的公平赛制。</p>
                                    <p>选票统计规则：</p>
                                    <p>1.启动官方统一投票平台，作为票数的唯一入口端</p>
                                    <p>2.本着尊重大众的原则，本次评选机制依然以网友选票和拉粉票数。</p>     
                                </div>
                                <div className="credit_content_footer text-center mt25">
                                    <div className="big35 a5">
                                        一切解释权归屋有岛官方所有
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </div>
        );
    }

}

export default Index