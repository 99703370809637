import { extendObservable, action, configure } from 'mobx'
import { queryCreditInfo, queryRanking } from "@/api/creditServer"
import { message } from "antd"

configure({ enforceActions: true})

class CreditStore{

   constructor(){
       this.reset(true)
   }

   @action
   reset = init=>{
    const state = {
        credit: [],
        user: [],
        creditList: [],
        creditLoading: false,
        creditFirstList: [],
        creditFourList: []
    }

    // 批量创建初始化的可观察对象属性，如果已创建则只重置其值，不再创建新的对象
    if (init) {
        extendObservable(this, state)
    } else {
        Object.keys(state).forEach(key => {
            this[key] = state[key]
            return null
        })
    }
   }

   @action
   resetSimul = () =>{
    const state = {
            credit: [],
            user: [],
            creditList: [],
            creditLoading: false,
            creditFirstList: [],
            creditFourList: []
        }
        Object.keys(state).forEach(key => {
            this[key] = state[key]
            return null
        })
   }

   @action
   toggleCreditLoading = () => {
        this.creditLoading = !this.creditLoading
   };

   @action
   updateContent = (key, val) => {
        this[key] = val
   };

   @action 
   getRanking = async(params={}) => {

        const data = await queryRanking(params)
   
        if(data.success){

            const creditFirstThirdList = data.list.slice(0, 3);
            const creditFourList  = _.drop(data.list, 3);
            
            let creditFirstList = []
            creditFirstList[0] = creditFirstThirdList[1]
            creditFirstList[1] = creditFirstThirdList[0]
            creditFirstList[2] = creditFirstThirdList[2]
            this.updateContent("creditFirstList", creditFirstList)
            this.updateContent("creditFourList", creditFourList)
        }
   };

   @action
   getCreditInfo = async(openid) => {
        this.toggleCreditLoading()
        
        const data = await queryCreditInfo(openid)

        if(data.success){
            const { user, credit, creditList } = data;

            this.updateContent("user", user);
            this.updateContent("credit", credit);

        }else{

            if("message" in data){
                message.error(`验证用户失败：${data.message}`)
            }
        }

        this.toggleCreditLoading()
   }

}

const creditStore = new CreditStore()

export default creditStore