import { extendObservable, action, configure } from 'mobx'
import { queryDisclaimer, queryTheme, querySubmit } from "@/api/disclaimerServer"
import { message } from "antd"

configure({ enforceActions: true})

class DisclaimerStore{

   constructor(){
       this.reset(true)
   }

   @action
   reset = init=>{
    const state = {
        stores: [],
        themeList: [],
        formResult: {}
    }

    // 批量创建初始化的可观察对象属性，如果已创建则只重置其值，不再创建新的对象
    if (init) {
        extendObservable(this, state)
    } else {
        Object.keys(state).forEach(key => {
            this[key] = state[key]
            return null
        })
    }
   }

   @action
   resetSimul = () =>{
    const state = {
            stores: [],
            themeList: [],
            formResult: {}
        }
        Object.keys(state).forEach(key => {
            this[key] = state[key]
            return null
        })
   }

   @action
   toggleDisclaimerLoading = () => {
        this.disclaimerLoading = !this.disclaimerLoading
   };

   @action
   updateContent = (key, val) => {
        this[key] = val
   };

   @action
   queryStore = async(openid) => {

        this.toggleDisclaimerLoading()

        const data = await queryDisclaimer(openid);
        
        if(data.success){
            const { stores } = data;

            this.updateContent("stores", stores);

        }else{

            if("message" in data){
                message.error(`验证用户失败：${data.message}`)
            }
        }

        this.toggleDisclaimerLoading()
   };

   @action 
   getThemeByStore = async(storeId) => {

        const data = await queryTheme(storeId);
        
        this.updateContent("themeList", data);
   };

   @action
   queryFormSubmit = async(params) => {

        const data = await querySubmit(params);
        return data;
   }
}

const disclaimerStore = new DisclaimerStore();

export default disclaimerStore