import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import '@/styles/global.less';
import 'antd-mobile/dist/antd-mobile.css';
import reportWebVitals from './reportWebVitals';

import Router from '@/router/index';
import Store from "@/store/index";
import 'lib-flexible'; 

// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
    <Provider {...Store}>
        <Router/>
    </Provider>
    ,document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
